<template>
  <div class="wrapper news-single">
    <BackLink :data="backlink" />
    <Section>
      <IndexInfoBlock
        :title="noticiasDetail.title"
        :bodyText="noticiasDetail.bodyText"
        :bodyImage="noticiasImage"
        :content="noticiasDetail.content"
        type="detail-column"
      />
    </Section>
    <Section v-if="legal">
      <HeadingBlock
        :data="{ blockText: legal }"
        textClass="legal "
        blockClass="full-width "
      />
    </Section>
    <Section v-if="seo">
      <SEO
        :seo="seo.single"
        :banner="seo.single.seoBannerImage"
        :link="seo.single.seoBannerLink"
      />
    </Section>
    <CTA />
  </div>
</template>

<script>
import BackLink from "@/components/Index/BackLink.vue";
import CTA from "@/components/CTA/CTA.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import IndexInfoBlock from "@/components/Index/IndexInfoBlock.vue";
import Section from "@/components/Section/Section.vue";
import SEO from "@/components/SEO/SEO.vue";

import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "NoticiaSingle",
  metaInfo() {
    return manageSEO(
      this.seo,
      this.seoHomepage,
      true,
      this.dealerName,
      null,
      null,
      this.noticiasDetail.title
    );
  },
  components: {
    BackLink,
    CTA,
    HeadingBlock,
    IndexInfoBlock,
    Section,
    SEO,
  },
  data() {
    return {
      backlink: {
        routting: "Noticias",
        label: "notícias",
      },
      dealerName: this.$store.state.dealerInfo.dealerName,
    };
  },
  computed: {
    noticiasData() {
      return this.$store.getters.getAllNews;
    },
    noticiasDetail() {
      return this.noticiasData.find((news) => {
        return news.slug == this.$route.params.slug;
      });
    },
    noticiasImage() {
      return {
        imageSrc: this.noticiasDetail.mainImage.imageUrl,
        imageAlt: this.noticiasDetail.mainImage.imageAlt,
        imageText: this.noticiasDetail.mainImage.imageTitle,
      };
    },
    seo() {
      return this.$store.state.noticiasSEO;
    },
    seoHomepage() {
      return this.$store.state.homepage.seo;
    },
    tcapSource() {
      return this.$store.state.noticiasSourceTCAP;
    },
    dealerSource() {
      return this.$store.state.noticiasSourceDealer;
    },
    legal() {
      return this.noticiasDetail.source == "tcap"
        ? this.tcapSource
        : this.dealerSource;
    },
  },
};
</script>
